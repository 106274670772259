<template>
  <CommonPages id="home" title="PicsFilm" classs="home">
    <div v-if="selectImgInfo" class="img-descript-container pc_padding">
      <div class="img-large-row">
        <img :src="selectImgInfo.regularImageUrl" alt="">
      </div>
      <div class="img-descript-main">
        <div class="img-descript-row">
          <div class="img-poster-row">
            <div class="img-poster-avatar-row">
              <img :src="selectImgInfo.userProfileSmallUrl" alt="">
              <div class="img-poster-name">
                {{ selectImgInfo.userName }}
              </div>
            </div>
            <div class="img-poster-like-row">
              <div class="imgposter cursor" :class="{ 'imgposter-like': likeStatus === 'like' }"
                @click="handleLike" />
            </div>
          </div>
          <div class="img-descript-content">
            {{ selectImgInfo.imgAltDesc }}
          </div>
        </div>
        <div class="img-tag-relate-row">
          <div class="tag-item cursor">
            {{ selectImgInfo.tags }}
          </div>
        </div>
      </div>
    </div>

    <AdComponent v-if="selectImgInfo" ref="ads-detail-1" class="m-b-40 content pc_padding"
      :ads="adsensConfig.detail_1" :showDebug="showDebug" />

    <section class="content pc_padding">
      <div v-if="!imgid" class="tag-row">
        <div v-for="(tag,i) in tagList" :key="i" :class="{'active':activeTag == tag}"
          class="tag-item cursor" @click="seclecTag(tag)">
          {{ tag }}
        </div>
      </div>

      <AdComponent v-if="!selectImgInfo" ref="ads-home-1" class="m-b-40" :ads="adsensConfig.home_1"
        :showDebug="showDebug" />

      <div v-if="selectImgInfo" class="relate-title">
        Related images
      </div>

      <masonry :cols="{ default: 4, 1200: 4, 700: 3, 500: 2 }" class="waterfall-container">
        <template v-for="(item, index) in images">
          <div v-if="!selectImgInfo || selectImgInfo && item.id != selectImgInfo.id" :key="index"
            class="waterfall-item" @mouseenter="currentHover = index"
            @mouseleave="currentHover = null" @click="choosePic(item.id)">
            <img :src="item.thumbImageUrl" :alt="item.alt">
            <div v-show="currentHover == index" v-if="item.imgAltDesc" class="shadow-txt-row">
              <div class="thumb-contnet">
                {{ item.imgAltDesc }}
              </div>
            </div>
          </div>
          <div v-if="shouldInsertAd(index) !== -1 && deviceType == 'mobile'" :key="`ad_${index}`"
            class="ad-inside-masonry ad-pos-row">
            <AdComponentIns v-if="!selectImgInfo" :ref="'adsm-' + index"
              :ads="adsensConfig.home_masonry[shouldInsertAd(index)]" class="m-b-40 admasonry-item"
              :showDebug="showDebug" />
            <AdComponentIns v-if="selectImgInfo" :ref="'adsm-' + index"
              :ads="adsensConfig.detail_masonry[shouldDetailInsertAd(index)]"
              class="m-b-40 admasonry-item" :showDebug="showDebug" />
          </div>
        </template>
      </masonry>
      <!-- <div class="ad-width-100">
        <AdComponent ref="adsfull" :ads="adsensConfig.home_full" :showDebug="showDebug" />
      </div> -->
    </section>
  </CommonPages>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import AdComponent from '@/pages/components/AdComponent.vue'
import AdComponentIns from '@/pages/components/AdComponentIns.vue'
import CommonPages from '@/pages/components/pc_common.vue'

import '@/css/common.scss';
import '@/css/blog_base.scss';

export default {
  name: 'Home',
  components: {
    AdComponent,
    CommonPages,
    AdComponentIns
  },
  mixins: [common],
  data () {
    return {
      images: [], // 初始数据
      page: 1,   // 当前页数
      isLoading: false, // 加载状态

      tagList: null,
      activeTag: null,
      currentHover: null, // 用于跟踪当前 hover 的索引

      likeStatus: null, // 'like' or 'unlike'

      selectImgInfo: null,
      imgid: null
    };
  },
  metaInfo () {
    return {
      title: this.imgid ? 'Beautiful PicsFilm | picsfilm.photos' : 'High-Quality 4K PicsFilms | picsfilm.photos',
      meta: [
        {
          name: 'description',
          content: this.selectImgInfo ? this.selectImgInfo.imgAltDesc : 'Discover a stunning collection of high-quality 4K PicsFilm at picsfilm.photos. Browse, download, and set beautiful ultra HD backgrounds for your desktop, mobile, or tablet. Transform your screen with our premium PicsFilm today.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "adsensConfig",
      'adPosConfig',
    ])
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
    // await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    this.loadAdSenseScript();  // 重新加载广告脚本
    this.getTagList()

    const { tag, imgid } = this.$route.params;
    console.log(imgid);
    this.imgid = imgid
    console.log(`tag: ${tag}`);
    if (tag) {
      this.$emit('updateMeta', tag); // 触发父组件更新 Meta 的事件
      this.activeTag = tag
    }
    if (imgid) {
      this.getImgInfo(imgid)
    }
    await this.getImages()
    this.displayAd('adsm-')
    // this.loadItems(); // 初次加载
    // 使用防抖包装滚动事件
    this.onScroll = this.debounce(this.onScroll.bind(this), 300);
    // 监听滚动事件
    window.addEventListener('scroll', this.onScroll);
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
    // 组件销毁时移除滚动事件监听
    // window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    loadLikeStatus () {
      // 从localStorage中读取当前图片的点赞状态
      const status = localStorage.getItem(`img_${this.selectImgInfo.id}_status`);
      if (status) {
        this.likeStatus = status;
      }
    },
    handleLike () {
      console.log(this.likeStatus);
      if (this.likeStatus !== 'like') {
        this.likeStatus = 'like'; // 改为点赞状态
        localStorage.setItem(`img_${this.selectImgInfo.id}_status`, 'like');
      } else {
        // 如果已经是点赞状态，再次点击恢复为点踩
        this.likeStatus = 'unlike';
        localStorage.setItem(`img_${this.selectImgInfo.id}_status`, 'unlike');
      }
    },
    //shuju
    async getImgInfo (id) {
      try {
        const res = await this.$global.server.getImgIdInfo({ id, type: 'film' })
        console.log(res)
        this.selectImgInfo = res.data
        this.loadLikeStatus();

      } catch (err) {
        console.log(err)
      }
    },
    async getTagList () {
      try {
        const res = await this.$global.server.getTagList({ type: 'film' })
        console.log(res)
        this.tagList = res.data
      } catch (err) {
        console.log(err)
      }
    },
    async getImages () {
      try {
        const params = {
          pageNum: this.page,
          pageSize: 30,
          type: 'film'
        };

        // 只有当 this.tag 不为 null 时，才添加 tag 字段
        if (this.activeTag !== null) {
          params.tag = this.activeTag;
        }

        const res = await this.$global.server.getImages(params);
        console.log(res)
        console.log('iiiiiiiiii');
        if (res.code == 200) {
          let data = res.data.photos
          this.images.push(...data)
          this.hasNext = res.data.hasNext
          this.page++;
        }
      } catch (err) {
        console.log(err)
      }
    },
    seclecTag (tag) {
      window.location.href = `/tag/${tag}`
    },
    choosePic (id) {
      window.location.href = `/${id}`
    },
    //pubu
    loadItems () {
      if (this.isLoading) return; // 如果已经在加载，则不重复加载
      this.isLoading = true; // 设置加载状态

      // 模拟异步加载
      setTimeout(() => {
        const newItems = Array.from({ length: 20 }, (_, i) => ({
          src: `https://via.placeholder.com/${i + 1}00x${i + 2}00?text=Item+${i + 1 + this.page * 10}`,
          alt: `Item ${i + 1 + this.page * 10}`,
        }));
        this.items.push(...newItems);
        this.page++;
        this.isLoading = false; // 加载完成后重置加载状态
      }, 500);
    },
    // 防抖函数
    debounce (func, delay) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    onScroll () {
      // 获取文档的总高度
      const documentHeight = document.documentElement.scrollHeight;
      // 当前滚动的高度
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      // 视口的高度
      const windowHeight = window.innerHeight;

      // 当滚动距离接近底部2000px时触发
      if (scrollTop + windowHeight >= documentHeight - 2000) {
        console.log('加载');
        this.getImages();
      }
    },
    //广告相关start
    shouldInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.home.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldDetailInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.detail.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.loadAdWithDelay()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    loadAdWithDelay () {
      setTimeout(() => {
        this.displayAd('ads-');
      }, 500);
    },
    async displayAd (tag) {
      await this.$nextTick();

      // 获取所有 ads 元素的 refs，过滤掉非广告的 ref
      const adsElements = Object.entries(this.$refs)
        .filter(([key, ref]) => key.startsWith(tag) && ref !== undefined) // 排除 undefined 的 refs
        .flatMap(([, ref]) => ref); // 展开并获取所有元素

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(this.displayAd, 500); // 延迟再次尝试
        return;
      }

      console.log(adsElements); // 检查是否包含 <ins> 标签
      adsElements.forEach(ad => {
        console.log(ad); // 输出每个广告元素
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      });
    },
    //广告相关end

    //ciyi

    goto (name) {
      this.$router.push({ name: name });
    },

  }
};
</script>
